





















































import BaseModel, { Form } from '../../../application/model/BaseModel'
import { Component, Prop, Ref } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import TooltipView from '@/components/common/TooltipView.vue'
import VitalsSource from '@/application/model/VitalsSource'
import Hospital from '@/application/model/Hospital'
import { GETTER_SELECTED_HOSPITAL } from '@/application/store/modules/selected/hospital/SelectedHospitalModule'
import { VitalsSourceType } from '@/application/model/VitalsSourceType'
import { ACTION_PUT_VITALS_SOURCE } from '@/application/store/modules/selected/hospital/SelectedHospitalVitalsSourcesModule'
import {
  buildDeepQualifiedStoreMethodPath,
  buildQualifiedStoreMethodPath,
  NAMESPACE_SELECTED_HOSPITAL
} from '@/application/store/RootStore'
import { NAMESPACE_VITALS_SOURCES } from '@/application/store/modules/selected/patient/SelectedPatientModule'

@Component({
  components: {
    Tooltip: TooltipView
  }
})
export default class AddVitalsSourceView extends BaseModel {
  @Prop({ required: true })
  vitalsSource!: VitalsSource

  newVitalsSource: VitalsSource = new VitalsSource()

  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_VITALS_SOURCES), ACTION_PUT_VITALS_SOURCE))
  putVitalsSource!: (patientVitalsSource: VitalsSource) => Promise<VitalsSource>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_HOSPITAL, GETTER_SELECTED_HOSPITAL))
  selectedHospital!: Hospital

  @Ref('FORM_REF')
  form!: Form

  isFormValid = true

  isDialogOpen = false

  vitalsSourceTypes: Array<string> = Array.from(Object.keys(VitalsSourceType))

  constructor () {
    super('vitals-source')
  }

  async mounted (): Promise<void> {
    this.newVitalsSource = JSON.parse(JSON.stringify(this.vitalsSource))
  }

  send (): void {
    if (this.form.validate()) {
      this.putVitalsSource(this.newVitalsSource)
        .then(() => {
          this.isDialogOpen = false
        })
    }
  }

  cancel (): void {
    this.isDialogOpen = false
  }
}
