




























import { Component } from 'vue-property-decorator'
import BaseModel from '../../../application/model/BaseModel'
import { DataTableHeader } from 'vuetify'
import Hospital from '@/application/model/Hospital'
import {
  GETTER_SELECTED_HOSPITAL,
  NAMESPACE_VITALS_SOURCES
} from '@/application/store/modules/selected/hospital/SelectedHospitalModule'
import {
  ACTION_FETCH_ALL_VITALS_SOURCES,
  GETTER_ALL_VITALS_SOURCES
} from '@/application/store/modules/selected/hospital/SelectedHospitalVitalsSourcesModule'
import { Action, Getter } from 'vuex-class'
import AddVitalsSource from '@/components/hospital/vitalsources/AddVitalsSourceView.vue'
import EditVitalsSource from '@/components/hospital/vitalsources/EditVitalsSourceView.vue'
import {
  buildDeepQualifiedStoreMethodPath,
  buildQualifiedStoreMethodPath,
  NAMESPACE_SELECTED_HOSPITAL
} from '@/application/store/RootStore'
import VitalsSource from '@/application/model/VitalsSource'

@Component({
  components: { EditVitalsSource, AddVitalsSource }
})
export default class VitalsSourcesView extends BaseModel {
  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_VITALS_SOURCES), ACTION_FETCH_ALL_VITALS_SOURCES))
  fetchHospitalVitalsSources!: () => Promise<Array<VitalsSource>>

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_VITALS_SOURCES), GETTER_ALL_VITALS_SOURCES))
  hospitalVitalsSources!: Array<VitalsSource>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_HOSPITAL, GETTER_SELECTED_HOSPITAL))
  selectedHospital!: Hospital

  constructor () {
    super('vitals-source')
  }

  get tableHeaders (): Array<DataTableHeader> {
    return [
      {
        text: this.getLangText('type'),
        value: 'type'
      },
      {
        text: this.getLangText('id'),
        value: 'id'
      },
      {
        text: this.getLangText('hardware-id'),
        value: 'hardwareId'
      },
      {
        text: this.getLangTextWithoutPrefix('patient.name'),
        value: 'patientName'
      },
      {
        text: this.getLangText('activate.timeFrom'),
        value: 'timeFrom'
      },
      {
        text: '',
        value: 'edit'
      }
    ]
  }

  getPatientName (vitalsSource: VitalsSource): string {
    if (!VitalsSource.isActive(vitalsSource)) {
      return this.getLangText('activate.notActivated')
    } else {
      return vitalsSource.active!.patient!.fullName!
    }
  }

  getTimeFrom (vitalsSource: VitalsSource): string {
    if (!VitalsSource.isActive(vitalsSource)) {
      return this.getLangText('activate.notActivated')
    } else {
      return vitalsSource.active!.timeFrom!
    }
  }

  async mounted (): Promise<void> {
    await this.fetchHospitalVitalsSources()
  }
}
