import Hospital from '@/application/model/Hospital'
import { VitalsSourceType } from '@/application/model/VitalsSourceType'
import PatientVitalsSource from '@/application/model/PatientVitalsSource'

export default class VitalsSource {
  id?: string

  type?: VitalsSourceType

  hardwareId?: string

  hospital?: Hospital

  active?: PatientVitalsSource

  static isActive (vitalsSource: VitalsSource): boolean {
    return !!vitalsSource.active
  }
}
