
































































import BaseModel, { Form } from '../../../application/model/BaseModel'
import { Component, Ref } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import TooltipView from '@/components/common/TooltipView.vue'
import VitalsSource from '@/application/model/VitalsSource'
import Hospital from '@/application/model/Hospital'
import {
  ACTION_POST_VITALS_SOURCE,
  GETTER_ALL_VITALS_SOURCES
} from '@/application/store/modules/selected/hospital/SelectedHospitalVitalsSourcesModule'
import { GETTER_SELECTED_HOSPITAL } from '@/application/store/modules/selected/hospital/SelectedHospitalModule'
import { VitalsSourceType } from '@/application/model/VitalsSourceType'
import {
  buildDeepQualifiedStoreMethodPath,
  buildQualifiedStoreMethodPath,
  NAMESPACE_SELECTED_HOSPITAL
} from '@/application/store/RootStore'
import { NAMESPACE_VITALS_SOURCES } from '@/application/store/modules/selected/patient/SelectedPatientModule'

@Component({
  components: {
    Tooltip: TooltipView
  }
})
export default class AddVitalsSourceView extends BaseModel {
  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_VITALS_SOURCES), ACTION_POST_VITALS_SOURCE))
  postVitalsSource!: (patientVitalsSource: VitalsSource) => Promise<VitalsSource>

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_VITALS_SOURCES), GETTER_ALL_VITALS_SOURCES))
  hospitalVitalsSources!: Array<VitalsSource>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_HOSPITAL, GETTER_SELECTED_HOSPITAL))
  selectedHospital!: Hospital

  isDialogOpen = false

  @Ref('FORM_REF')
  form!: Form

  isFormValid = true

  vitalsSourceTypeValues: Array<string> = Array.from(Object.keys(VitalsSourceType))

  vitalsSource = new VitalsSource()

  constructor () {
    super('vitals-source')
  }

  ruleIsNotDuplicate (hardwareId: string) {
    if (!hardwareId || !this.hospitalVitalsSources) {
      return false
    }
    const duplicates: Array<VitalsSource> =
      this.hospitalVitalsSources
        .filter(vitalsSource =>
          vitalsSource.hardwareId!.trim().toLowerCase() === hardwareId.trim().toLowerCase())
    return duplicates.length === 0 ||
      this.getLangText('add.duplicate')
  }

  send (): void {
    if (this.form.validate()) {
      this.postVitalsSource(this.vitalsSource)
        .then(() => {
          this.isDialogOpen = false
        })
    }
  }

  close (): void {
    this.form.reset()
    this.isDialogOpen = false
  }
}
